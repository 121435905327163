/* src/components/ContactSection.css */

.contact-section {
    padding: 50px 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
  }
  
  .contact-section h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .contact-section p {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .contact-section .form-control {
    margin-bottom: 10px;
  }
  
  .contact-section .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  
  .contact-section .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  