/* src/components/Navbar.css */

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.top-bar {
  background: linear-gradient(to right, #676054, #537a7c); /* Match navbar color with opacity */
  color: #f0f4f8;
}

.top-bar a {
  color: #f0f4f8;
  text-decoration: none;
}

.top-bar a:hover {
  color: #007bff;
}

.top-bar span {
  color: #343a40;
}

.top-bar svg {
  vertical-align: middle;
}

.top-bar .top-bar-icon {
  font-size: 1.2em; /* Increase icon size */
}

.custom-navbar {
  background-color: #8e9783 !important; /* Light blue color with opacity */
  font-family: "Roboto", sans-serif;
  font-weight: 50;
  font-style: italic;
  transition: background-color 0.3s ease-in-out; /* Smooth transition for color changes */
}

.custom-navbar.transparent {
  background-color: rgba(142, 151, 131, 0.5) !important; /* Transparent background color */
}

.navbar-logo {
  height: 80px; /* Adjust the height of the logo */
}

.navbar-light .navbar-nav .nav-link {
  color: #50473a;
  font-size: 1.3rem;
  font-weight: 500;
  margin-left: 10px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fdf8e3;
}

@media (max-width: 576px) {
  .top-bar .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .top-bar {
    text-align: center;
  }

  .navbar-logo {
    height: 40px; /* Adjust logo size for mobile */
  }

  .top-bar .top-bar-icon {
    font-size: 1.5em; /* Increase icon size for mobile */
  }
}
