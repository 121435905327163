@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* src/components/Home.css */

.custom-carousel {
    position: relative;
    max-height: 500px;
    /* Set the maximum height for the carousel */
    box-shadow: 0 4px 8px #555c4c;
    /* Light blue shadow on the right */
}

.carousel-image {
    max-height: 500px;
    /* Set the maximum height for the images */
    object-fit: cover;
    /* Ensure the images cover the container */
    transition: transform 1s ease-in-out;
    /* Smooth transition for images */
}

.carousel-caption-custom {
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    animation: fadeInUp 1s ease-in-out;
}

.carousel-caption-custom h3 {
    font-family: 'Lora', serif;
    /* Apply the Lora font for the title */
    font-size: 2rem;
    font-weight: 700;
}

.carousel-caption-custom p {
    font-family: 'Poppins', sans-serif;
    /* Apply the Poppins font for the subtext */
    font-size: 1.2rem;
    font-weight: 300;
}

.shadow-right {
    box-shadow: 10px 0px 20px rgba(173, 216, 230, 0.5);
    /* Match navbar color with opacity */
}

/* Custom styles for the new caption section */
.centered-caption {
    background-color: #8e9783;
    /* Slightly darker than the navbar color */
    text-align: center;
    padding: 50px 20px;
    animation: fadeIn 2s ease-in-out;
}

.centered-caption h2 {
    font-family: 'Great Vibes', cursive;
    /* Apply the Great Vibes font for the caption */
    font-size: 3rem;
    color: white;
    /* -webkit-text-stroke: 1px #50473a; 
    text-stroke: 1px #50473a;  */
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
    /* animation: pulse 2s infinite; */
}

.centered-welcome{
    background-color: #8e9783;
    /* Slightly darker than the navbar color */
    text-align: center;
    padding: 50px 20px;
    animation: fadeIn 2s ease-in-out;
}

.centered-welcome h2 {
    font-family: 'Great Vibes', cursive;
    /* Apply the Great Vibes font for the caption */
    font-size: 3rem;
    color: white;
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    animation: pulse 2s infinite; */
}

/* Custom styles for the wedding section */
.wedding-section {
    text-align: center;
    padding: 50px 20px;
}

.wedding-heading h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    color: #333;
    position: relative;
}

.wedding-heading .underline {
    width: 100px;
    height: 4px;
    background-color: #333;
    margin: 10px auto;
    position: relative;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.5s ease-in-out;
}

.wedding-heading.animate .underline {
    transform: scaleX(1);
}

.wedding-heading p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    color: #555;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.wedding-heading.animate p {
    opacity: 1;
}

.wedding-images .wedding-image-container {
    text-align: center;
    margin-top: 20px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px;
    /* Adjust height as needed */
    display: flex;
    align-items: flex-end;
    padding: 20px;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.wedding-images.animate .wedding-image-container {
    opacity: 1;
    transform: translateY(0);
}

.wedding-text {
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
}

.wedding-image-container h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    color: #fff;
    margin-top: 15px;
}

.wedding-image-container p {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #fff;
    margin-top: 10px;
}

/* Custom styles for the featured section */
.featured-section {
    text-align: center;
    padding: 50px 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    color: white;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.featured-section.animate {
    opacity: 1;
    transform: translateY(0);
}

.featured-section:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    /* Dark overlay */
    z-index: 1;
}

.featured-image-container {
    margin-bottom: 20px;
}

.featured-text-container {
    position: relative;
    z-index: 2;
    text-align: left;
}

.featured-text-container h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    color: #fff;
    margin-top: 10px;
}

.featured-text-container h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    color: #fff;
    margin-top: 10px;
}

.featured-text-container p {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #fff;
    margin-top: 10px;
}

.btn-learn-more {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #434d38;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.btn-learn-more:hover {
    background-color: #8ca86d;
    color: white;
}

/* Custom styles for the feature product section */
.feature-product-section {
    text-align: center;
    padding: 50px 20px;
    background-color: rgba(240, 240, 240, 1);
    opacity: 1;
    transform: translateY(20px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.feature-product-section.animate {
    opacity: 1;
    transform: translateY(0);
}

.feature-product-heading h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    color: #333;
    position: relative;
}

.feature-product-heading .underline {
    width: 100px;
    height: 4px;
    background-color: #333;
    margin: 10px auto;
    position: relative;
    transform: scaleX(1);
    transform-origin: center;
    transition: transform 0.5s ease-in-out;
}

.feature-product-heading.animate .underline {
    transform: scaleX(1);
}

.feature-product-heading p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    color: #555;
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.feature-product-heading.animate p {
    opacity: 1;
}

.feature-product-images {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.feature-product-image-container {
    text-align: center;
    margin-top: 20px;
    opacity: 1;
    transform: translateY(20px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.feature-product-images.animate .feature-product-image-container {
    opacity: 1;
    transform: translateY(0);
}

.feature-product-image {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 20px;
}

.middle-image {
    order: 1;
    /* Position the middle image first */
}

.feature-product-text {
    text-align: center;
}

.feature-product-text h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    color: #333;
}

.feature-product-text p {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #555;
}

/* Custom styles for the carousel controls */
.custom-prev-icon,
.custom-next-icon {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    /* Initially hidden */
    transition: opacity 0.3s ease, transform 0.3s ease;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.custom-prev-icon::before,
.custom-next-icon::before {
    content: '';
    background-size: 1.5rem 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
}

.custom-prev-icon::before {
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23fff" viewBox="0 0 8 8"%3E%3Cpath d="M4 0L0 4l4 4V5h4V3H4V0z"/%3E%3C/svg%3E');
    /* Left arrow */
}

.custom-next-icon::before {
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23fff" viewBox="0 0 8 8"%3E%3Cpath d="M4 0L8 4 4 8V5H0V3h4V0z"/%3E%3C/svg%3E');
    /* Right arrow */
}

.custom-prev-icon {
    left: 50px;
}

.custom-next-icon {
    right: 50px;
}

.custom-carousel:hover .custom-prev-icon,
.custom-carousel:hover .custom-next-icon {
    opacity: 1;
    /* Show on hover */
}

.custom-carousel:hover .custom-prev-icon {
    transform: translateY(-50%) translateX(40px);
    /* Slide in from left */
}

.custom-carousel:hover .custom-next-icon {
    transform: translateY(-50%) translateX(-40px);
    /* Slide in from right */
}

.custom-prev-icon:hover::before,
.custom-next-icon:hover::before {
    transform: scale(1.1);
}

/* Custom styles for the new section */
.new-section {
    text-align: left;
    padding: 50px 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    color: white;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.new-section.animate {
    opacity: 1;
    transform: translateY(0);
}

.new-section:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    /* Dark overlay */
    z-index: 1;
}

.new-text-container {
    position: relative;
    z-index: 2;
    text-align: left;
}

.new-text-container h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    color: #fff;
    margin-top: 10px;
}

.new-text-container h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    color: #fff;
    margin-top: 10px;
}

.new-text-container p {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #fff;
    margin-top: 10px;
}

.new-image-container {
    position: relative;
    z-index: 2;
    text-align: left;
}

.new-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    /* Ensure the image covers the container */
    object-position: center;
    /* Center the image */
}

/* Custom styles for the testimonials section */
.testimonials-section {
    text-align: center;
    padding: 50px 20px;
    /* background-color: rgba(240, 240, 240, 1); */
}

.testimonials-section h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    color: #333;
}

.testimonials-carousel {
    .slick-slide {
        padding: 10px;
    }
}

.testimonial-item {
    background: #676054;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.testimonial-avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 29%;
    margin-bottom: 5px;
    /* border: 3px solid #007bff; */
}

.testimonial-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.testimonial-content h3 {
    font-family: 'Lora', serif;
    text-align: center;
    font-size: 1.5rem;
    color: rgb(255, 255, 255);
    margin-bottom: 5px;
}

.testimonial-content p {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-size: 1rem;
    color: rgb(230, 221, 221);
}

@media (max-width: 576px) {
    .carousel-caption-custom {
        font-size: 0.9rem;
    }

    .custom-carousel {
        max-height: 300px;
        /* Adjust max-height for mobile view */
    }

    .carousel-image {
        max-height: 300px;
        /* Adjust image max-height for mobile view */
    }

    .custom-prev-icon,
    .custom-next-icon {
        width: 2.5rem;
        height: 2.5rem;
    }

    .custom-prev-icon::before,
    .custom-next-icon::before {
        background-size: 1.2rem 1.2rem;
        width: 1.2rem;
        height: 1.2rem;
    }

    .centered-caption h2 {
        font-size: 2rem;
    }

    .wedding-heading h2 {
        font-size: 2rem;
    }

    .featured-text-container {
        text-align: center;
        padding: 20px;
    }

    .featured-image-container {
        text-align: center;
        padding: 20px;
    }

    .featured-section {
        padding: 20px;
    }

    .feature-product-images {
        flex-direction: column;
    }

    .feature-product-image-container {
        order: 0;
    }

    .new-text-container {
        text-align: center;
        padding: 20px;
    }

    .new-image-container {
        text-align: center;
        padding: 20px;
    }

    .new-section {
        padding: 20px;
    }

    .testimonials-carousel {
        .slick-slide {
            padding: 10px;
        }
    }

    .testimonial-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .testimonial-avatar {
        margin-right: 0;
        margin-bottom: 10px;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes pulse {

    0%,
    100% {
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }

    50% {
        text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7);
    }
}
/* src/components/ContactSection.css */

.contact-section {
    padding: 50px 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
  }
  
  .contact-section h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .contact-section p {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .contact-section .form-control {
    margin-bottom: 10px;
  }
  
  .contact-section .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  
  .contact-section .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  
/* src/components/Footer.css */

.footer-section {
    background: linear-gradient(to right, #676054, #537a7c);
    color: white;
    padding: 40px 0;
    text-align: left;
  }
  
  .footer-logo {
    max-width: 150px;
    margin-bottom: 20px;
  }
  
  .footer-section h5 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .footer-section p {
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 10px;
  }
  
  .footer-section ul li a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-section ul li a:hover {
    color: #f0a500;
  }
  
  .social-media-icons a {
    color: white;
    font-size: 1.5rem;
    margin-right: 10px;
    transition: color 0.3s ease;
  }
  
  .social-media-icons a:hover {
    color: #f0a500;
  }
  
  .footer-bottom {
    margin-top: 20px;
    text-align: center;
  }
  
  .footer-bottom p {
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem;
    margin-bottom: 0;
  }
  
  .footer-bottom a {
    color: #f0a500;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-bottom a:hover {
    color: white;
  }
  
/* src/components/Portfolio.css */
.portfolio-hero-section {
    background-image: url(../../static/media/portfolio-hero.88bdf06c.png);
 /* Adjust the path to your hero image */
    background-size: cover;
    background-position: center;
    position: relative;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align to the left */
    padding-left: 50px; /* Add padding to the left */
  }
  
  .portfolio-hero-overlay {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: left; /* Align text to the left */
    padding: 50px;
    border-radius: 10px;
  }
  
  .portfolio-hero-overlay h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.5px; /* Adjust letter spacing for the title */
  }
  
  .portfolio-hero-button {
    background-color: transparent !important; /* Ensure no background color */
    border: 2px solid white;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: color 0.3s ease, box-shadow 0.3s ease;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.2px; /* Adjust letter spacing for the button */
  }
  
  .portfolio-hero-button:hover {
    background-color: transparent !important; /* Ensure no background color on hover */
    color: #007bff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .portfolio-albums-section {
    padding: 50px 0;
  }
  
  .portfolio-albums-section h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    margin-bottom: 40px;
    letter-spacing: 1.5px; /* Adjust letter spacing for the section heading */
  }
  
  .portfolio-album {
    margin-bottom: 30px;
    cursor: pointer;
    position: relative;
    transition: transform 0.3s ease;
  }
  
  .portfolio-album:hover {
    transform: scale(1.05);
  }
  
  .portfolio-album img {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .portfolio-album-caption {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    margin-top: 10px;
    letter-spacing: 1px; /* Adjust letter spacing for the captions */
  }
  
  @media (max-width: 576px) {
    .portfolio-hero-overlay h1 {
      font-size: 2rem;
    }
  
    .portfolio-hero-button {
      font-size: 0.9rem;
    }
  
    .portfolio-albums-section h2 {
      font-size: 2rem;
    }
  
    .portfolio-album img {
      border-radius: 5px;
    }
  
    .portfolio-album-caption {
      font-size: 1rem;
    }
  }
  
/* src/components/Services.css */

.services-header-section {
    background-size: cover;
    background-position: center;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .services-header-overlay {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: justify;
    padding: 50px;
    border-radius: 10px;
  }
  
  .services-header-overlay h1 {
    font-size: 3rem;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.5px;
  }
  
  .services-specialties-section {
    padding: 50px 0;
  }
  
  .section-heading {
    text-align: center;
    margin-bottom: 40px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease, transform 1s ease;
  }
  
  .section-heading.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  .section-heading h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    letter-spacing: 1.5px;
  }
  
  .section-heading p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  .service-item {
    text-align: center;
    margin-bottom: 30px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease, transform 1s ease;
  }
  
  .service-item.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  .service-item img {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .service-item h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .service-item p {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
  }
  
  .all-services-button {
    background-color: #007bff;
    border-color: #007bff;
    color: white;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.2px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  
  .all-services-button:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  
  @media (max-width: 576px) {
    .services-header-overlay h1 {
      font-size: 2rem;
    }
  
    .section-heading h2 {
      font-size: 2rem;
    }
  
    .service-item h3 {
      font-size: 1.2rem;
    }
  
    .service-item p {
      font-size: 0.9rem;
    }
  }
  
/* src/components/AboutUs.css */

.about-header-section {
    background-size: cover;
    background-position: center;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .about-header-overlay {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    padding: 50px;
    border-radius: 10px;
  }
  
  .about-header-overlay h1 {
    font-size: 3rem;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.5px;
  }
  
  .about-content-section {
    padding: 50px 0;
  }
  
  .about-section {
    margin-bottom: 50px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease, transform 1s ease;
  }
  
  .about-section.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  .about-image img {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .about-text h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    letter-spacing: 1.2px;
    margin-bottom: 20px;
  }
  
  .about-text p {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  @media (max-width: 576px) {
    .about-header-overlay h1 {
      font-size: 2rem;
    }
  
    .about-text h2 {
      font-size: 1.5rem;
    }
  
    .about-text p {
      font-size: 0.9rem;
    }
  }
  
/* src/components/css/ContactUs.css */

.contactus-header-section {
    background-size: cover;
    background-position: center;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .contactus-header-overlay {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    padding: 50px;
    border-radius: 10px;
  }
  
  .contactus-header-overlay h1 {
    font-size: 3rem;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.5px;
  }
  
  .contactus-content-section {
    padding: 50px 0;
  }
  
  .contactus-section {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease, transform 1s ease;
  }
  
  .contactus-section.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
/* src/components/GetAQuote.css */

.get-a-quote-container {
    padding: 50px 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .get-a-quote-container h1 {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  
  .get-a-quote-container .form-group {
    margin-bottom: 15px;
  }
  
  .get-a-quote-container .form-control {
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
  }
  
  .get-a-quote-container .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    font-family: 'Poppins', sans-serif;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  
  .get-a-quote-container .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  
/* src/components/Navbar.css */

.top-bar {
  background: linear-gradient(to right, #676054, #537a7c); /* Match navbar color with opacity */
  color: #f0f4f8;
}

.top-bar a {
  color: #f0f4f8;
  text-decoration: none;
}

.top-bar a:hover {
  color: #007bff;
}

.top-bar span {
  color: #343a40;
}

.top-bar svg {
  vertical-align: middle;
}

.top-bar .top-bar-icon {
  font-size: 1.2em; /* Increase icon size */
}

.custom-navbar {
  background-color: #8e9783 !important; /* Light blue color with opacity */
  font-family: "Roboto", sans-serif;
  font-weight: 50;
  font-style: italic;
  transition: background-color 0.3s ease-in-out; /* Smooth transition for color changes */
}

.custom-navbar.transparent {
  background-color: rgba(142, 151, 131, 0.5) !important; /* Transparent background color */
}

.navbar-logo {
  height: 80px; /* Adjust the height of the logo */
}

.navbar-light .navbar-nav .nav-link {
  color: #50473a;
  font-size: 1.3rem;
  font-weight: 500;
  margin-left: 10px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fdf8e3;
}

@media (max-width: 576px) {
  .top-bar .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .top-bar {
    text-align: center;
  }

  .navbar-logo {
    height: 40px; /* Adjust logo size for mobile */
  }

  .top-bar .top-bar-icon {
    font-size: 1.5em; /* Increase icon size for mobile */
  }
}

/* src/components/PrivacyPolicy.css */

.privacy-policy-container {
    padding: 50px 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .privacy-policy-container h1,
  .privacy-policy-container h2 {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container p {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container ul {
    list-style-type: disc;
    padding-left: 20px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container ul li {
    margin-bottom: 10px;
  }
  
/* src/components/CookiePolicy.css */

.cookie-policy-container {
    padding: 50px 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .cookie-policy-container h1,
  .cookie-policy-container h2 {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  
  .cookie-policy-container p {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  
  .cookie-policy-container ul {
    list-style-type: disc;
    padding-left: 20px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  
  .cookie-policy-container ul li {
    margin-bottom: 10px;
  }
  
/* src/components/PrivacyPolicy.css */

.privacy-policy-container {
    padding: 50px 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .privacy-policy-container h1,
  .privacy-policy-container h2 {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container p {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container ul {
    list-style-type: disc;
    padding-left: 20px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container ul li {
    margin-bottom: 10px;
  }
  
/* src/components/PrivacyPolicy.css */

.privacy-policy-container {
    padding: 50px 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .privacy-policy-container h1,
  .privacy-policy-container h2 {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container p {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container ul {
    list-style-type: disc;
    padding-left: 20px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container ul li {
    margin-bottom: 10px;
  }
  
