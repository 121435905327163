/* src/components/GetAQuote.css */

.get-a-quote-container {
    padding: 50px 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .get-a-quote-container h1 {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  
  .get-a-quote-container .form-group {
    margin-bottom: 15px;
  }
  
  .get-a-quote-container .form-control {
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
  }
  
  .get-a-quote-container .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    font-family: 'Poppins', sans-serif;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  
  .get-a-quote-container .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  