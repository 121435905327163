/* src/components/Services.css */

.services-header-section {
    background-size: cover;
    background-position: center;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .services-header-overlay {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: justify;
    padding: 50px;
    border-radius: 10px;
  }
  
  .services-header-overlay h1 {
    font-size: 3rem;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.5px;
  }
  
  .services-specialties-section {
    padding: 50px 0;
  }
  
  .section-heading {
    text-align: center;
    margin-bottom: 40px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease, transform 1s ease;
  }
  
  .section-heading.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  .section-heading h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    letter-spacing: 1.5px;
  }
  
  .section-heading p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  .service-item {
    text-align: center;
    margin-bottom: 30px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease, transform 1s ease;
  }
  
  .service-item.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  .service-item img {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .service-item h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .service-item p {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
  }
  
  .all-services-button {
    background-color: #007bff;
    border-color: #007bff;
    color: white;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.2px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  
  .all-services-button:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  
  @media (max-width: 576px) {
    .services-header-overlay h1 {
      font-size: 2rem;
    }
  
    .section-heading h2 {
      font-size: 2rem;
    }
  
    .service-item h3 {
      font-size: 1.2rem;
    }
  
    .service-item p {
      font-size: 0.9rem;
    }
  }
  