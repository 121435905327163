/* src/components/css/ContactUs.css */

.contactus-header-section {
    background-size: cover;
    background-position: center;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .contactus-header-overlay {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    padding: 50px;
    border-radius: 10px;
  }
  
  .contactus-header-overlay h1 {
    font-size: 3rem;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.5px;
  }
  
  .contactus-content-section {
    padding: 50px 0;
  }
  
  .contactus-section {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease, transform 1s ease;
  }
  
  .contactus-section.animate {
    opacity: 1;
    transform: translateY(0);
  }
  