/* src/components/Footer.css */

.footer-section {
    background: linear-gradient(to right, #676054, #537a7c);
    color: white;
    padding: 40px 0;
    text-align: left;
  }
  
  .footer-logo {
    max-width: 150px;
    margin-bottom: 20px;
  }
  
  .footer-section h5 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .footer-section p {
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 10px;
  }
  
  .footer-section ul li a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-section ul li a:hover {
    color: #f0a500;
  }
  
  .social-media-icons a {
    color: white;
    font-size: 1.5rem;
    margin-right: 10px;
    transition: color 0.3s ease;
  }
  
  .social-media-icons a:hover {
    color: #f0a500;
  }
  
  .footer-bottom {
    margin-top: 20px;
    text-align: center;
  }
  
  .footer-bottom p {
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem;
    margin-bottom: 0;
  }
  
  .footer-bottom a {
    color: #f0a500;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-bottom a:hover {
    color: white;
  }
  