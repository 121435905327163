/* src/components/PrivacyPolicy.css */

.privacy-policy-container {
    padding: 50px 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .privacy-policy-container h1,
  .privacy-policy-container h2 {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container p {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container ul {
    list-style-type: disc;
    padding-left: 20px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container ul li {
    margin-bottom: 10px;
  }
  