/* src/components/AboutUs.css */

.about-header-section {
    background-size: cover;
    background-position: center;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .about-header-overlay {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    padding: 50px;
    border-radius: 10px;
  }
  
  .about-header-overlay h1 {
    font-size: 3rem;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.5px;
  }
  
  .about-content-section {
    padding: 50px 0;
  }
  
  .about-section {
    margin-bottom: 50px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease, transform 1s ease;
  }
  
  .about-section.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  .about-image img {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .about-text h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    letter-spacing: 1.2px;
    margin-bottom: 20px;
  }
  
  .about-text p {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  @media (max-width: 576px) {
    .about-header-overlay h1 {
      font-size: 2rem;
    }
  
    .about-text h2 {
      font-size: 1.5rem;
    }
  
    .about-text p {
      font-size: 0.9rem;
    }
  }
  