/* src/components/Home.css */

.custom-carousel {
    position: relative;
    max-height: 500px;
    /* Set the maximum height for the carousel */
    box-shadow: 0 4px 8px #555c4c;
    /* Light blue shadow on the right */
}

.carousel-image {
    max-height: 500px;
    /* Set the maximum height for the images */
    object-fit: cover;
    /* Ensure the images cover the container */
    transition: transform 1s ease-in-out;
    /* Smooth transition for images */
}

.carousel-caption-custom {
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    animation: fadeInUp 1s ease-in-out;
}

.carousel-caption-custom h3 {
    font-family: 'Lora', serif;
    /* Apply the Lora font for the title */
    font-size: 2rem;
    font-weight: 700;
}

.carousel-caption-custom p {
    font-family: 'Poppins', sans-serif;
    /* Apply the Poppins font for the subtext */
    font-size: 1.2rem;
    font-weight: 300;
}

.shadow-right {
    box-shadow: 10px 0px 20px rgba(173, 216, 230, 0.5);
    /* Match navbar color with opacity */
}

/* Custom styles for the new caption section */
.centered-caption {
    background-color: #8e9783;
    /* Slightly darker than the navbar color */
    text-align: center;
    padding: 50px 20px;
    animation: fadeIn 2s ease-in-out;
}

.centered-caption h2 {
    font-family: 'Great Vibes', cursive;
    /* Apply the Great Vibes font for the caption */
    font-size: 3rem;
    color: white;
    /* -webkit-text-stroke: 1px #50473a; 
    text-stroke: 1px #50473a;  */
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
    /* animation: pulse 2s infinite; */
}

.centered-welcome{
    background-color: #8e9783;
    /* Slightly darker than the navbar color */
    text-align: center;
    padding: 50px 20px;
    animation: fadeIn 2s ease-in-out;
}

.centered-welcome h2 {
    font-family: 'Great Vibes', cursive;
    /* Apply the Great Vibes font for the caption */
    font-size: 3rem;
    color: white;
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    animation: pulse 2s infinite; */
}

/* Custom styles for the wedding section */
.wedding-section {
    text-align: center;
    padding: 50px 20px;
}

.wedding-heading h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    color: #333;
    position: relative;
}

.wedding-heading .underline {
    width: 100px;
    height: 4px;
    background-color: #333;
    margin: 10px auto;
    position: relative;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.5s ease-in-out;
}

.wedding-heading.animate .underline {
    transform: scaleX(1);
}

.wedding-heading p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    color: #555;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.wedding-heading.animate p {
    opacity: 1;
}

.wedding-images .wedding-image-container {
    text-align: center;
    margin-top: 20px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px;
    /* Adjust height as needed */
    display: flex;
    align-items: flex-end;
    padding: 20px;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.wedding-images.animate .wedding-image-container {
    opacity: 1;
    transform: translateY(0);
}

.wedding-text {
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
}

.wedding-image-container h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    color: #fff;
    margin-top: 15px;
}

.wedding-image-container p {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #fff;
    margin-top: 10px;
}

/* Custom styles for the featured section */
.featured-section {
    text-align: center;
    padding: 50px 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    color: white;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.featured-section.animate {
    opacity: 1;
    transform: translateY(0);
}

.featured-section:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    /* Dark overlay */
    z-index: 1;
}

.featured-image-container {
    margin-bottom: 20px;
}

.featured-text-container {
    position: relative;
    z-index: 2;
    text-align: left;
}

.featured-text-container h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    color: #fff;
    margin-top: 10px;
}

.featured-text-container h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    color: #fff;
    margin-top: 10px;
}

.featured-text-container p {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #fff;
    margin-top: 10px;
}

.btn-learn-more {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #434d38;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.btn-learn-more:hover {
    background-color: #8ca86d;
    color: white;
}

/* Custom styles for the feature product section */
.feature-product-section {
    text-align: center;
    padding: 50px 20px;
    background-color: rgba(240, 240, 240, 1);
    opacity: 1;
    transform: translateY(20px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.feature-product-section.animate {
    opacity: 1;
    transform: translateY(0);
}

.feature-product-heading h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    color: #333;
    position: relative;
}

.feature-product-heading .underline {
    width: 100px;
    height: 4px;
    background-color: #333;
    margin: 10px auto;
    position: relative;
    transform: scaleX(1);
    transform-origin: center;
    transition: transform 0.5s ease-in-out;
}

.feature-product-heading.animate .underline {
    transform: scaleX(1);
}

.feature-product-heading p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    color: #555;
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.feature-product-heading.animate p {
    opacity: 1;
}

.feature-product-images {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.feature-product-image-container {
    text-align: center;
    margin-top: 20px;
    opacity: 1;
    transform: translateY(20px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.feature-product-images.animate .feature-product-image-container {
    opacity: 1;
    transform: translateY(0);
}

.feature-product-image {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 20px;
}

.middle-image {
    order: 1;
    /* Position the middle image first */
}

.feature-product-text {
    text-align: center;
}

.feature-product-text h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    color: #333;
}

.feature-product-text p {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #555;
}

/* Custom styles for the carousel controls */
.custom-prev-icon,
.custom-next-icon {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    /* Initially hidden */
    transition: opacity 0.3s ease, transform 0.3s ease;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.custom-prev-icon::before,
.custom-next-icon::before {
    content: '';
    background-size: 1.5rem 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
}

.custom-prev-icon::before {
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23fff" viewBox="0 0 8 8"%3E%3Cpath d="M4 0L0 4l4 4V5h4V3H4V0z"/%3E%3C/svg%3E');
    /* Left arrow */
}

.custom-next-icon::before {
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23fff" viewBox="0 0 8 8"%3E%3Cpath d="M4 0L8 4 4 8V5H0V3h4V0z"/%3E%3C/svg%3E');
    /* Right arrow */
}

.custom-prev-icon {
    left: 50px;
}

.custom-next-icon {
    right: 50px;
}

.custom-carousel:hover .custom-prev-icon,
.custom-carousel:hover .custom-next-icon {
    opacity: 1;
    /* Show on hover */
}

.custom-carousel:hover .custom-prev-icon {
    transform: translateY(-50%) translateX(40px);
    /* Slide in from left */
}

.custom-carousel:hover .custom-next-icon {
    transform: translateY(-50%) translateX(-40px);
    /* Slide in from right */
}

.custom-prev-icon:hover::before,
.custom-next-icon:hover::before {
    transform: scale(1.1);
}

/* Custom styles for the new section */
.new-section {
    text-align: left;
    padding: 50px 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    color: white;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.new-section.animate {
    opacity: 1;
    transform: translateY(0);
}

.new-section:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    /* Dark overlay */
    z-index: 1;
}

.new-text-container {
    position: relative;
    z-index: 2;
    text-align: left;
}

.new-text-container h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    color: #fff;
    margin-top: 10px;
}

.new-text-container h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    color: #fff;
    margin-top: 10px;
}

.new-text-container p {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #fff;
    margin-top: 10px;
}

.new-image-container {
    position: relative;
    z-index: 2;
    text-align: left;
}

.new-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    /* Ensure the image covers the container */
    object-position: center;
    /* Center the image */
}

/* Custom styles for the testimonials section */
.testimonials-section {
    text-align: center;
    padding: 50px 20px;
    /* background-color: rgba(240, 240, 240, 1); */
}

.testimonials-section h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    color: #333;
}

.testimonials-carousel {
    .slick-slide {
        padding: 10px;
    }
}

.testimonial-item {
    background: #676054;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.testimonial-avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 29%;
    margin-bottom: 5px;
    /* border: 3px solid #007bff; */
}

.testimonial-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.testimonial-content h3 {
    font-family: 'Lora', serif;
    text-align: center;
    font-size: 1.5rem;
    color: rgb(255, 255, 255);
    margin-bottom: 5px;
}

.testimonial-content p {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-size: 1rem;
    color: rgb(230, 221, 221);
}

@media (max-width: 576px) {
    .carousel-caption-custom {
        font-size: 0.9rem;
    }

    .custom-carousel {
        max-height: 300px;
        /* Adjust max-height for mobile view */
    }

    .carousel-image {
        max-height: 300px;
        /* Adjust image max-height for mobile view */
    }

    .custom-prev-icon,
    .custom-next-icon {
        width: 2.5rem;
        height: 2.5rem;
    }

    .custom-prev-icon::before,
    .custom-next-icon::before {
        background-size: 1.2rem 1.2rem;
        width: 1.2rem;
        height: 1.2rem;
    }

    .centered-caption h2 {
        font-size: 2rem;
    }

    .wedding-heading h2 {
        font-size: 2rem;
    }

    .featured-text-container {
        text-align: center;
        padding: 20px;
    }

    .featured-image-container {
        text-align: center;
        padding: 20px;
    }

    .featured-section {
        padding: 20px;
    }

    .feature-product-images {
        flex-direction: column;
    }

    .feature-product-image-container {
        order: 0;
    }

    .new-text-container {
        text-align: center;
        padding: 20px;
    }

    .new-image-container {
        text-align: center;
        padding: 20px;
    }

    .new-section {
        padding: 20px;
    }

    .testimonials-carousel {
        .slick-slide {
            padding: 10px;
        }
    }

    .testimonial-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .testimonial-avatar {
        margin-right: 0;
        margin-bottom: 10px;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes pulse {

    0%,
    100% {
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }

    50% {
        text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7);
    }
}