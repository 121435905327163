/* src/components/Portfolio.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
.portfolio-hero-section {
    background-image: url('../../assets/portfolio/portfolio-hero.png');
 /* Adjust the path to your hero image */
    background-size: cover;
    background-position: center;
    position: relative;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align to the left */
    padding-left: 50px; /* Add padding to the left */
  }
  
  .portfolio-hero-overlay {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: left; /* Align text to the left */
    padding: 50px;
    border-radius: 10px;
  }
  
  .portfolio-hero-overlay h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.5px; /* Adjust letter spacing for the title */
  }
  
  .portfolio-hero-button {
    background-color: transparent !important; /* Ensure no background color */
    border: 2px solid white;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: color 0.3s ease, box-shadow 0.3s ease;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.2px; /* Adjust letter spacing for the button */
  }
  
  .portfolio-hero-button:hover {
    background-color: transparent !important; /* Ensure no background color on hover */
    color: #007bff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .portfolio-albums-section {
    padding: 50px 0;
  }
  
  .portfolio-albums-section h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    margin-bottom: 40px;
    letter-spacing: 1.5px; /* Adjust letter spacing for the section heading */
  }
  
  .portfolio-album {
    margin-bottom: 30px;
    cursor: pointer;
    position: relative;
    transition: transform 0.3s ease;
  }
  
  .portfolio-album:hover {
    transform: scale(1.05);
  }
  
  .portfolio-album img {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .portfolio-album-caption {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    margin-top: 10px;
    letter-spacing: 1px; /* Adjust letter spacing for the captions */
  }
  
  @media (max-width: 576px) {
    .portfolio-hero-overlay h1 {
      font-size: 2rem;
    }
  
    .portfolio-hero-button {
      font-size: 0.9rem;
    }
  
    .portfolio-albums-section h2 {
      font-size: 2rem;
    }
  
    .portfolio-album img {
      border-radius: 5px;
    }
  
    .portfolio-album-caption {
      font-size: 1rem;
    }
  }
  